import { createSlice } from '@reduxjs/toolkit';

export interface StaffState {
  selectedLocation: string;
}

const initialState: StaffState = {
  selectedLocation: '',
};

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    selectLocation: (state: StaffState, action: { payload: string }) => {
      state.selectedLocation = action.payload;
    },
  },
});

export const { selectLocation } = staffSlice.actions;

export default staffSlice.reducer;
