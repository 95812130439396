import { createSlice } from '@reduxjs/toolkit';
import { BLANK_LOCATION, ILocation } from '../business/locations/types';
import { DateRangeType, IDateRange } from './types';
import GetDateRange from './utils';

interface ReportsState {
  selectedDateRangeFilter: DateRangeType | null;
  selectedDateRange: IDateRange | null;
  selectedLocation: ILocation;
}

const initialState: ReportsState = {
  selectedDateRangeFilter: null,
  selectedDateRange: null,
  selectedLocation: BLANK_LOCATION,
};

export const ReportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setSelectedDateRangeFilter: (
      state: ReportsState,
      action: { payload: DateRangeType; type: string }
    ) => {
      state.selectedDateRangeFilter = action.payload;
      state.selectedDateRange = GetDateRange(action.payload);
    },
    setSelectedDateRange: (
      state: ReportsState,
      action: { payload: IDateRange | null; type: string }
    ) => {
      state.selectedDateRange = action.payload;
    },
    setSelectedLocation: (
      state: ReportsState,
      action: { payload: ILocation | undefined; type: string }
    ) => {
      state.selectedLocation = action.payload ?? BLANK_LOCATION;
    },
  },
  extraReducers: () => {},
});

export const {
  setSelectedDateRangeFilter,
  setSelectedDateRange,
  setSelectedLocation,
} = ReportsSlice.actions;

export default ReportsSlice.reducer;
