
const configuration = {
  welcomeMessage: "Hello from PROD",
  urls: {
    baseUrl: "https://api.spaccurate.com",
    IDENTITY_SERVER: "https://iam.spaccurate.com",
    IDENTITY_SERVER_AUTHORIZE_ENDPOINT: "/connect/authorize",
    IDENTITY_SERVER_TOKEN_ENDPOINT: "/connect/token",
    IDENTITY_SERVER_LOGOUT_ENDPOINT: "/connect/endsession",
    availability: "/availability",
    SQUARE_APPLICATION_ID: "sq0idp-0DI4vtLrQCH4hsZiDuwgyQ",
    SQUARE_LOCATION_ID: "LVM9V6XNTAPQV",
    STRIPE_DASHBOARD: "https://dashboard.stripe.com/{{accountId}}/dashboard"
  },
  INSTRUMENTATION_KEY: "9aa3de4f-6e23-4ee8-816b-32af463969dc",
  GOOGLE_OAUTH_CLIENT_ID: '911405620407-v3pfvf93laco87s8ajqps45sc0k8l5s3.apps.googleusercontent.com',
  STRIPE_PUBLISHABLE_KEY: "pk_live_51OWmWWAbGz2rAwn2yoTPeAYjDkqYgxPAlUqDVZrvNgkDj4bbVSV3bHWYtg0eYQADT38AyL8OBzciYC0As58zKAtO003rSxufwd"
}

export default configuration
