/* eslint-disable no-await-in-loop */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosClient } from '@src/app/AxiosClient';
import { IOrder, TransactionStatus } from '../api/OrderApi';
import { PaymentMethod } from './types';
import { IGiftCard } from '../gift-cards/types';

export interface CustomerState {
  selectedTipAmount: string;
  selectedPaymentAmount: string;
  checkoutResult: TransactionStatus | null;
  checkoutPaymentId: string | null;
  selectedPaymentMethod: PaymentMethod | null;
  selectedPaymentMethodId: string;
  selectedCardId: string | null;
  selectedDeviceId: string | null;
  selectedPaymentToken: string | null;
  saveCard: boolean;
  selectedMemo: string;
  selectedGiftCard: IGiftCard | null;
  giftCardAppliedCode: string;
  clientSecret: string | null;
  orderId: string | null;
  transactionId: string | null;
  marketplaceFeeCharge: number | null;
}

const initialState: CustomerState = {
  selectedTipAmount: '',
  selectedPaymentAmount: '',
  checkoutResult: null,
  checkoutPaymentId: null,
  selectedPaymentMethod: null,
  selectedPaymentMethodId: '',
  selectedCardId: null,
  selectedPaymentToken: null,
  selectedDeviceId: null,
  saveCard: true,
  selectedMemo: '',
  selectedGiftCard: null,
  giftCardAppliedCode: '',
  clientSecret: null,
  orderId: null,
  transactionId: null,
  marketplaceFeeCharge: null,
};

type ITerminalCheckoutData = {
  orderId: string | null;
  amountInDollars: number | null;
  deviceId: string | null;
  memo: string | null;
};

export const createTerminalCheckout = createAsyncThunk<
  TransactionStatus,
  ITerminalCheckoutData,
  { state: { customer: CustomerState } }
>(
  'customers/createTerminalCheckout',
  async (checkoutData: ITerminalCheckoutData, { getState, dispatch }) => {
    const api = new AxiosClient({});
    const createCheckoutResponse = await api.postRequest(
      `/order/${checkoutData.orderId}/checkout`,
      checkoutData
    );

    const checkoutLocation = createCheckoutResponse.headers.location.split('/');
    const checkoutId = checkoutLocation[checkoutLocation.length - 1];
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(selectCheckoutPaymentId(checkoutId));

    let order: IOrder;
    while (getState().customer.checkoutResult !== TransactionStatus.Canceled) {
      const orderResponse = await api.getRequest(
        `/order/${checkoutData.orderId}`
      );
      order = orderResponse.data;
      const terminalPayment = order.transactions.find(
        (t) => t.id === checkoutId
      );
      if (
        terminalPayment?.status === TransactionStatus.Captured ||
        terminalPayment?.status === TransactionStatus.Canceled
      ) {
        return terminalPayment?.status!;
      }
      await new Promise((f) => setTimeout(f, 5000));
    }
    return TransactionStatus.Canceled;
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    selectTipAmount: (state: CustomerState, action: { payload: string }) => {
      state.selectedTipAmount = action.payload;
    },
    selectPaymentAmount: (
      state: CustomerState,
      action: { payload: string }
    ) => {
      state.selectedPaymentAmount = action.payload;
    },
    selectCheckoutResult: (
      state: CustomerState,
      action: { payload: TransactionStatus | null }
    ) => {
      state.checkoutResult = action.payload;
    },
    selectCheckoutPaymentId: (
      state: CustomerState,
      action: { payload: string | null }
    ) => {
      state.checkoutPaymentId = action.payload;
    },
    resetCustomerPaymentState: (state: CustomerState) => {
      state.checkoutPaymentId = null;
      state.selectedTipAmount = '';
      state.checkoutResult = null;
      state.selectedPaymentAmount = '';
      state.selectedPaymentMethod = null;
      state.selectedPaymentMethodId = '';
      state.selectedCardId = null;
      state.selectedPaymentToken = null;
      state.selectedDeviceId = null;
      state.selectedMemo = '';
      state.selectedGiftCard = null;
      state.giftCardAppliedCode = '';
      state.orderId = null;
      state.transactionId = null;
      state.clientSecret = null;
      state.marketplaceFeeCharge = null;
    },
    selectPaymentMethod: (
      state: CustomerState,
      action: {
        payload: {
          paymentMethod: PaymentMethod | null;
        };
        type: string;
      }
    ) => {
      state.selectedPaymentMethod = action.payload.paymentMethod;
    },
    selectPaymentMethodId: (
      state: CustomerState,
      action: {
        payload: {
          paymentMethodId: string;
        };
        type: string;
      }
    ) => {
      state.selectedPaymentMethodId = action.payload.paymentMethodId;
    },
    selectCardId: (
      state: CustomerState,
      action: {
        payload: {
          cardId: string | null;
        };
        type: string;
      }
    ) => {
      state.selectedCardId = action.payload.cardId;
    },
    selectPaymentToken: (
      state: CustomerState,
      action: {
        payload: {
          paymentToken: string | null;
        };
        type: string;
      }
    ) => {
      state.selectedPaymentToken = action.payload.paymentToken;
    },
    selectGiftCard: (
      state: CustomerState,
      action: {
        payload: {
          giftCard: IGiftCard | null;
        };
        type: string;
      }
    ) => {
      state.selectedGiftCard = action.payload.giftCard;
    },
    setGiftCardAppliedCode: (
      state: CustomerState,
      action: {
        payload: {
          giftCardCode: string;
        };
        type: string;
      }
    ) => {
      state.giftCardAppliedCode = action.payload.giftCardCode;
    },
    selectDeviceId: (
      state: CustomerState,
      action: {
        payload: {
          deviceId: string | null;
        };
        type: string;
      }
    ) => {
      state.selectedDeviceId = action.payload.deviceId;
    },
    setSaveCard: (state: CustomerState) => {
      state.saveCard = !state.saveCard;
    },
    setMemo: (
      state: CustomerState,
      action: {
        payload: string | null;
        type: string;
      }
    ) => {
      state.selectedMemo = action.payload ?? '';
    },
    setClientSecret: (
      state: CustomerState,
      action: { payload: string | null }
    ) => {
      state.clientSecret = action.payload;
    },
    setOrderId: (state: CustomerState, action: { payload: string | null }) => {
      state.orderId = action.payload;
    },
    setTransactionId: (
      state: CustomerState,
      action: { payload: string | null }
    ) => {
      state.transactionId = action.payload;
    },
    setMarketplaceCharge: (
      state: CustomerState,
      action: { payload: number | null }
    ) => {
      state.marketplaceFeeCharge = action.payload;
    },
    resetPaymentStateAfterOrderUpdateError: (state: CustomerState) => {
      if (state.selectedPaymentMethod !== PaymentMethod.NewCard) {
        state.selectedPaymentMethod = null;
        state.selectedPaymentMethodId = '';
      }
      state.giftCardAppliedCode = '';
      state.selectedCardId = null;
      state.selectedGiftCard = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTerminalCheckout.pending, (state) => {
        state.checkoutResult = TransactionStatus.Pending;
      })
      .addCase(createTerminalCheckout.fulfilled, (state, action) => {
        state.checkoutResult = action.payload;
      })
      .addCase(createTerminalCheckout.rejected, (state) => {
        state.checkoutResult = TransactionStatus.Failed;
      });
  },
});

export const {
  selectTipAmount,
  selectPaymentAmount,
  selectCheckoutResult,
  selectCheckoutPaymentId,
  resetCustomerPaymentState,
  selectPaymentMethod,
  selectPaymentMethodId,
  setSaveCard,
  selectCardId,
  selectDeviceId,
  selectPaymentToken,
  selectGiftCard,
  setGiftCardAppliedCode,
  setMemo,
  setClientSecret,
  setOrderId,
  setTransactionId,
  setMarketplaceCharge,
  resetPaymentStateAfterOrderUpdateError,
} = customerSlice.actions;

export default customerSlice.reducer;
