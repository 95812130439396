import { createSlice } from '@reduxjs/toolkit';

export type CalendarSelection = {
  locationId: string;
  locationName: string;
  calendarId: string;
  calendarTitle: string;
  locationTimeZone: string;
};

interface IntegrationsState {
  selectedLocations: string[];
  selectedGoogleCalendars: CalendarSelection[];
}

const initialState: IntegrationsState = {
  selectedLocations: [],
  selectedGoogleCalendars: [],
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    selectGoogleCalendars: (
      state: IntegrationsState,
      action: { payload: CalendarSelection[]; type: string }
    ) => {
      state.selectedGoogleCalendars = action.payload;
      state.selectedLocations = [
        ...new Set(action.payload.map((c) => c.locationId)),
      ];
    },
  },
});

export const { selectGoogleCalendars } = integrationsSlice.actions;

export default integrationsSlice.reducer;
