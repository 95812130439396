// ** Reducers Imports
import { api, apiExample } from '@src/features/api';
import staff from '@src/features/staff/StaffSlice';
import auth from '../features/authentication/AuthenticationSlice';
import navbar from '../features/navbar/navbar';
import layout from '../features/layout/layout';
import calendar from '../features/calendar/CalendarSlice';
import therapistSchedule from '../features/therapist-schedule/TherapistScheduleSlice';
import business from '../features/business/BusinessInformationSlice';
import register from '../features/register/RegisterSlice';
import customer from '../features/customers/CustomersSlice';
import reports from '../features/reports/ReportsSlice';
import integrations from '../features/calendar/integrations/IntegrationsSlice';
import timeSlotFiltering from '../features/calendar/add-appointment/components/select-time-slot-step/TimeSlotFilteringSlice';
import giftCard from '../features/gift-cards/GiftCardSlice';

const rootReducer = {
  auth,
  navbar,
  layout,
  calendar,
  therapistSchedule,
  business,
  register,
  customer,
  reports,
  staff,
  integrations,
  timeSlotFiltering,
  giftCard,
  [api.reducerPath]: api.reducer,
  [apiExample.reducerPath]: apiExample.reducer,
};

export default rootReducer;
