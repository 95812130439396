import { IAddOn } from '../services/types';

export enum UnitOfWorkStatus {
  Initial,
  Loading,
  Success,
  Error,
}

export enum AppointmentStatus {
  Pending = 'pending',
  Scheduled = 'scheduled',
  Canceled = 'canceled',
  Expired = 'expired',
  Abandoned = 'abandoned',
  NoShow = 'noshow',
  Arrived = 'arrived',
  CheckedIn = 'checkedin',
  CheckedOut = 'checkedout',
  Completed = 'completed',
}

export enum TherapistPreference {
  Any = 'any',
  Male = 'male',
  Female = 'female',
  Specific = 'specific',
}

export enum BookingSource {
  Customer = 'Customer',
  Staff = 'Staff',
  System = 'System',
  Customer_Mobile = 'Customer_Mobile',
  Customer_Web = 'Customer_Web',
}

export interface ISchedule {
  appointments: IAppointmentResponse[];
  blockedTimes: IBlockedTimeResponse[];
}

export interface IAppointmentResponse {
  id: string;
  businessId: string;
  customer: IScheduleCustomer;
  location: IScheduleLocation;
  room: IScheduleRoom;
  recipients: IScheduleRecipient[];
  therapists: IScheduleTherapist[];
  treatments: IScheduleTreatment[];
  addOns: IScheduleAddOn[];
  timeSlot: IScheduleTimeSlot;
  groupSize: number;
  bookingId: string;
  bookingDate: string;
  bookingSourceId: string;
  bookingSourceType: BookingSource;
  status: AppointmentStatus;
  internalNotes: string;
  changeHistory: IScheduleChange[];
  localDateOnly: string;
}

export interface IBlockedTimeResponse {
  id: string;
  businessId: string;
  therapist: IScheduleTherapist;
  timeSlot: IScheduleTimeSlot;
  blockCreatedDate: string;
  blockSourceId: string;
  internalNotes: string;
  isActive: boolean;
  localDateOnly: string;
}

export interface IScheduleCustomer {
  id: string;
  customerId?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface IScheduleLocation {
  id: string;
  name: string;
  address: string;
  phoneNumber: string;
}

export interface IScheduleRoom {
  id: string;
  name: string;
}

export interface IScheduleRecipient {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  therapistPreference: TherapistPreference;
  therapistId: string | null;
  treatmentId: string | null;
  recipientIndex: number | null;
}

export interface IScheduleTherapist {
  id: string;
  staffId: string | null;
  firstName: string;
  lastName: string;
  gender: string;
}

export interface IScheduleTreatment {
  serviceId: string | null;
  name: string;
  type: string;
  category: string;
  price: string;
}

export interface IScheduleAddOn {
  id?: string;
  name: string;
  price: string;
  recipient?: number;
}

export interface IScheduleTimeSlot {
  starts: string;
  ends: string;
  durationInMinutes: number;
  bufferTimeInMinutes: number;
  timeZone: string;
}

export interface IScheduleChange {
  bookingSourceId: string;
  bookingSourceType: string;
  action: string;
  date: string;
}

export interface IAppointmentForm {
  room: IAvailabilityRoom;
  recipients: IScheduleRecipient[];
  therapists: IAvailabilityTherapist[];
  treatments: IAvailabilityTreatment[];
  addOns: IAddOn[][];
  bufferTime: boolean;
}

export interface IExtendAppointmentForm {
  treatments: IRatedTreatment[];
  durationInMinutes: number;
  includeBufferTime: boolean;
}

export interface IAddOnsForm {
  recipients: IScheduleRecipient[];
  addOns: IAddOn[][];
}

export interface IRecipientInfoForm {
  recipients: IScheduleRecipient[];
}

export interface IBlockedTimeForm {
  therapist: IScheduleTherapist;
  start: Date;
  end: Date;
  internalNotes: string;
}

export const BLANK_SCHEDULE_ROOM: IScheduleRoom = {
  id: '',
  name: '',
};

export const BLANK_SCHEDULE_RECIPIENT: IScheduleRecipient = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  therapistPreference: TherapistPreference.Any,
  therapistId: '',
  treatmentId: '',
  recipientIndex: 1,
};

export const BLANK_SCHEDULE_THERAPIST: IScheduleTherapist = {
  id: '',
  staffId: '',
  firstName: '',
  lastName: '',
  gender: '',
};

export const BLANK_SCHEDULE_TREATMENT: IScheduleTreatment = {
  serviceId: '',
  name: '',
  type: '',
  category: '',
  price: '',
};

export const BLANK_SCHEDULE_TIME_SLOT: IScheduleTimeSlot = {
  starts: '',
  ends: '',
  durationInMinutes: 0,
  bufferTimeInMinutes: 0,
  timeZone: '',
};

export const BLANK_APPOINTMENT_RESPONSE: IAppointmentResponse = {
  id: '',
  businessId: '',
  customer: {
    id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  location: {
    id: '',
    name: '',
    address: '',
    phoneNumber: '',
  },
  room: BLANK_SCHEDULE_ROOM,
  recipients: [],
  therapists: [],
  treatments: [],
  addOns: [],
  timeSlot: BLANK_SCHEDULE_TIME_SLOT,
  groupSize: 0,
  bookingId: '',
  bookingDate: '',
  bookingSourceId: '',
  bookingSourceType: BookingSource.Customer,
  status: AppointmentStatus.Pending,
  internalNotes: '',
  changeHistory: [],
  localDateOnly: '',
};

export enum EventType {
  Appointment = 'Appointment',
  BlockedTime = 'BlockedTime',
  OperationException = 'OperationException',
  Availability = 'Availability',
}

export interface ScheduleEvent {
  id?: string;
  url?: string;
  title?: string;
  start?: Date;
  end?: Date;
  allDay?: boolean;
  // overlap?: boolean;
  groupId?: string;
  resourceId?: string;
  resourceIds?: string[];
  display?: string;
  backgroundColor?: string;
  extendedProps?: {
    type?: EventType;
    treatmentName?: string;
    customerName?: string;
    roomName?: string;
    therapistPreference?: TherapistPreference;
    bufferTime?: number;
    status?: AppointmentStatus;
    groupSize?: number;
    notes?: string;
    bookingSource?: BookingSource;
    hasAddons?: boolean;
    bookingDate?: Date;
    bookingId?: string;
    localStartDateOnly?: string;
  };
}

// ** Blank Event Object
export const BLANK_EVENT: ScheduleEvent = {
  title: '',
  start: new Date(),
  end: new Date(),
  allDay: false,
  url: '',
  extendedProps: {},
};

export const BLANK_SCHEDULE: ISchedule = {
  appointments: [],
  blockedTimes: [],
};

/* ************ */
/* Availability */
/* ************ */

export interface IAvailabilityRate {
  singleRate: number;
  couplesRate: number;
}

export interface IAvailabilityTreatmentRate {
  id: string;
  rate: number;
}

export interface IAvailabilityRoom {
  roomId: string;
  name: string;
  capacity: number;
}

export interface IAvailabilityTreatment {
  id: string;
  name: string;
  type: string;
  duration: number;
  category: string;
}

export interface IAvailabilityTherapist {
  accountId: string;
  staffId: string;
  firstName: string;
  lastName: string;
  gender: string;
  treatments: IAvailabilityTreatment[];
}

export interface IAvailabilityTimeSlot {
  start: string;
  duration: number;
}

export interface IAvailableSlot {
  id: string;
  category: string;
  baseRate: number;
  treatmentRates: IAvailabilityTreatmentRate[];
  selectedRoom: IAvailabilityRoom;
  rooms: IAvailabilityRoom[];
  therapists: IAvailabilityTherapist[];
  treatments: IAvailabilityTreatment[];
  timeSlot: IAvailabilityTimeSlot;
  groupSize: number;
}

export interface IAvailabilityResources {
  rooms: IAvailabilityRoom[];
  therapists: IAvailabilityTherapist[];
  treatments: IRatedTreatment[];
}

export interface IRatedTreatment {
  id: string;
  name: string;
  type: string;
  duration: number;
  category: string;
  rate: number;
}

export const BLANK_AVAILABILITY_ROOM: IAvailabilityRoom = {
  roomId: '',
  name: '',
  capacity: 0,
};

export const BLANK_AVAILABILITY: IAvailableSlot = {
  id: '',
  category: '',
  baseRate: 0,
  treatmentRates: [],
  selectedRoom: BLANK_AVAILABILITY_ROOM,
  rooms: [],
  therapists: [],
  treatments: [],
  timeSlot: {
    start: '',
    duration: 0,
  },
  groupSize: 0,
};

export const BLANK_AVAILABILITY_TREATMENT: IAvailabilityTreatment = {
  id: '',
  name: '',
  type: '',
  duration: 0,
  category: '',
};

export const BLANK_AVAILABILITY_THERAPIST: IAvailabilityTherapist = {
  accountId: '',
  staffId: '',
  firstName: '',
  lastName: '',
  gender: '',
  treatments: [],
};

export const BLANK_AVAILABILITY_RESOURCES: IAvailabilityResources = {
  rooms: [],
  therapists: [],
  treatments: [],
};

export const BLANK_APPOINTMENT_FORM: IAppointmentForm = {
  room: BLANK_AVAILABILITY_ROOM,
  recipients: [],
  therapists: [],
  treatments: [],
  addOns: [],
  bufferTime: true,
};

export const BLANK_EXTEND_APPOINTMENT_FORM: IExtendAppointmentForm = {
  treatments: [],
  durationInMinutes: 0,
  includeBufferTime: true,
};

export const BLANK_ADD_ONS_FORM: IAddOnsForm = {
  recipients: [],
  addOns: [],
};

export const BLANK_RECIPIENT_INFO_FORM: IRecipientInfoForm = {
  recipients: [],
};

const getDate = () => {
  const date = new Date();
  date.setHours(12, 0, 0, 0);
  return date;
};

export const BLANK_BLOCKED_TIME_FORM: IBlockedTimeForm = {
  therapist: BLANK_SCHEDULE_THERAPIST,
  start: getDate(),
  end: getDate(),
  internalNotes: '',
};
