export interface ICustomer {
  id: string;
  businessCustomerId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  internalNotes: string;
  bookingNotes: string;
  checkInNotes: string;
  checkOutNotes: string;
}

export const BLANK_CUSTOMER: ICustomer = {
  id: '',
  businessCustomerId: '',
  fullName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  internalNotes: '',
  bookingNotes: '',
  checkInNotes: '',
  checkOutNotes: '',
};

export enum PaymentMethod {
  NewCard = 'NewCard',
  CardOnFile = 'CardOnFile',
  GiftCard = 'GiftCard',
  RefundToGiftCard = 'RefundToGiftCard',
  Voucher = 'Voucher',
  Terminal = 'Terminal',
}
