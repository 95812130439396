import { createSlice } from '@reduxjs/toolkit';
import {
  IGiftCardCustomerDetails,
  IGiftCardOrderDetails,
  IPurchaseGiftCardForm,
} from './types';

interface GiftCardState {
  giftCardShoppingCart: IPurchaseGiftCardForm[];
  giftCardOrder: IGiftCardOrderDetails | null;
  customerDetails: IGiftCardCustomerDetails | null;
}

const initialState: GiftCardState = {
  giftCardShoppingCart: [],
  giftCardOrder: null,
  customerDetails: null,
};

export const GiftCardSlice = createSlice({
  name: 'gift-card',
  initialState,
  reducers: {
    addToCart: (
      state: GiftCardState,
      action: { payload: IPurchaseGiftCardForm }
    ) => {
      state.giftCardShoppingCart = [
        ...state.giftCardShoppingCart,
        action.payload,
      ];
    },
    setGiftCardOrder: (
      state: GiftCardState,
      action: { payload: IGiftCardOrderDetails | null }
    ) => {
      state.giftCardOrder = action.payload;
    },
    setCustomerDetails: (
      state: GiftCardState,
      action: { payload: IGiftCardCustomerDetails | null }
    ) => {
      state.customerDetails = action.payload;
    },
    removeFromCart: (state: GiftCardState, action: { payload: string }) => {
      state.giftCardShoppingCart = [
        ...state.giftCardShoppingCart.filter((gc) => gc.id !== action.payload),
      ];
    },
    clearCart: (state: GiftCardState) => {
      state.giftCardShoppingCart = [];
      state.customerDetails = null;
    },
  },
  extraReducers: () => {},
});

export const {
  addToCart,
  setGiftCardOrder,
  setCustomerDetails,
  removeFromCart,
  clearCart,
} = GiftCardSlice.actions;

export default GiftCardSlice.reducer;
