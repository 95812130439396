/* eslint-disable no-await-in-loop */
import { ConvertDateToSpecificTimeZoneDate } from '@src/utility/Utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosClient } from '@src/app/AxiosClient';
import {
  Credit,
  CreditType,
  IAppointmentRequest,
  IRescheduleAppointmentRequest,
} from '../api/ScheduleApi';
import {
  BLANK_LOCATION,
  ILocation,
  TimeZone,
} from '../business/locations/types';
import { CustomerState } from '../customers/CustomersSlice';
import { BLANK_CUSTOMER, ICustomer } from '../customers/types';
import {
  BLANK_AVAILABILITY,
  BLANK_AVAILABILITY_RESOURCES,
  IAppointmentResponse,
  IAvailabilityResources,
  IAvailableSlot,
  ScheduleEvent,
} from './types';

const maxAttempts = 30;
const timeoutMs = 2000;

export enum BookingStatus {
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success',
  Fail = 'Fail',
  Canceled = 'Canceled',
}

export interface IBookingTreatment {
  name: string;
  price: string;
  category: string;
}

export interface IBookingAddOn {
  name: string;
  price: string;
  recipient?: number;
}

export interface IBookingTimeSlot {
  starts: string;
  durationInMinutes: number;
}

export interface IBookingRequest {
  appointmentId: string;
  addOns: IBookingAddOn[];
  treatments: IBookingTreatment[];
  timeSlot: IBookingTimeSlot;
  locationId: string;
  timeZone: string;
  orderId: string;
  businessId: string;
  email: string;
  firstName: string;
  saveCardForFutureBookings: boolean;
  customerId: string;
  credits: Credit[];
}

interface CalendarState {
  selectedEvent: ScheduleEvent;
  availableResourcesForEdit: IAvailabilityResources;
  availableResourcesForExtension: IAvailabilityResources;
  selectedLocation: ILocation;
  selectedCustomer: ICustomer;
  selectedAvailability: IAvailableSlot;
  selectedServiceCategory: string | null;
  selectedDateRange?: {
    current: Date;
    start: Date;
    end: Date;
  };
  validTherapistIds: string[][];
  validTreatmentIds: string[][];
  validAddOnIds: string[][];
  pendingAddAppointmentRequest: IAppointmentRequest | null;
  appointmentReservationId: string;
  bookingStatus: BookingStatus;
  bookingFailureCode: string;
  bookingFailureReason: string;
  appointmentRescheduleDetails: IRescheduleAppointmentRequest | null;
  selectedAppointmentForReschedule: IAppointmentResponse | null;
  selectedAppointmentForExtension: IAppointmentResponse | null;
  showCanceledAppointments: boolean;
}

const initialState: CalendarState = {
  selectedEvent: {},
  availableResourcesForEdit: BLANK_AVAILABILITY_RESOURCES,
  availableResourcesForExtension: BLANK_AVAILABILITY_RESOURCES,
  selectedLocation: BLANK_LOCATION,
  selectedCustomer: BLANK_CUSTOMER,
  selectedAvailability: BLANK_AVAILABILITY,
  selectedServiceCategory: null,
  selectedDateRange: undefined,
  validTherapistIds: [],
  validTreatmentIds: [],
  validAddOnIds: [],
  pendingAddAppointmentRequest: null,
  appointmentReservationId: '',
  bookingStatus: BookingStatus.Initial,
  bookingFailureCode: '',
  bookingFailureReason: '',
  appointmentRescheduleDetails: null,
  selectedAppointmentForReschedule: null,
  selectedAppointmentForExtension: null,
  showCanceledAppointments: false,
};

type IPaymentData = {
  saveCard: boolean;
  reservedAppointment: IAppointmentResponse;
  orderId: string;
};

type IBookingChange = {
  id: string;
  changeSourceId: string;
  action: string;
  dateUtc: string;
};
type BookingFailure = {
  failureCode: string;
  reason: string;
};
type IBookingResponse = {
  status: BookingStatus;
  id: string;
  sagaState: string;
  failureCode: string;
  changeHistory: IBookingChange[];
  timeZone: TimeZone;
  failures: BookingFailure[];
};

type PollForResultArgs = {
  bookingId: string;
  successChangeHistoryAction: string;
  failureChangeHistoryAction?: string;
};

export const pollForBookingResult = createAsyncThunk<
  IBookingResponse,
  PollForResultArgs
>('booking/pollForBookingResult', async (args: PollForResultArgs) => {
  const api = new AxiosClient({});

  let attempts = 0;
  let bookingResponseData: IBookingResponse = {
    status: BookingStatus.Initial,
    failureCode: '',
    id: '',
    changeHistory: [],
    sagaState: '',
    timeZone: TimeZone['America/New_York'],
    failures: [],
  };
  while (attempts < maxAttempts) {
    const bookingStatusResponse = await api.getRequest(
      `/booking/${args.bookingId}`
    );
    bookingResponseData = bookingStatusResponse.data as IBookingResponse;

    const changeHistoryEvents = bookingResponseData.changeHistory.filter(
      (c) =>
        c.action === args.successChangeHistoryAction ||
        (args.failureChangeHistoryAction &&
          c.action === args.failureChangeHistoryAction)
    );

    const oneMinuteAgo = new Date(Date.now() - 1000 * 60);

    const { timeZone } = bookingResponseData;
    const changeEventWithinTime = changeHistoryEvents.filter(
      (c) =>
        oneMinuteAgo <
        ConvertDateToSpecificTimeZoneDate(new Date(c?.dateUtc), timeZone)
    )[0];
    if (changeEventWithinTime) {
      if (changeEventWithinTime.action === args.successChangeHistoryAction) {
        return {
          ...bookingResponseData,
          status: BookingStatus.Success,
        };
      }
      return {
        ...bookingResponseData,
        status: BookingStatus.Fail,
      };
    }
    attempts += 1;
    await new Promise((f) => setTimeout(f, timeoutMs));
  }
  if (attempts === maxAttempts) {
    return {
      ...bookingResponseData,
      status: BookingStatus.Fail,
      failureCode: 'timeout',
    };
  }
  return bookingResponseData;
});

export const submitBooking = createAsyncThunk<
  IBookingResponse,
  IPaymentData,
  { state: { calendar: CalendarState; customer: CustomerState } }
>('booking/submitBooking', async (paymentData: IPaymentData, { getState }) => {
  const { calendar, customer } = getState();
  const credits: Credit[] = [];
  if (customer.selectedGiftCard) {
    credits.push({
      type: CreditType.GiftCard,
      paymentToken: customer.selectedGiftCard.code,
      isGlobal: customer.selectedGiftCard.canBeUsedAtOtherBusinesses,
    });
  }
  const request: IBookingRequest = {
    orderId: paymentData.orderId,
    businessId: paymentData.reservedAppointment.businessId,
    locationId: calendar.selectedLocation.id,
    timeZone: calendar.selectedLocation.timeZone,
    customerId: calendar.selectedCustomer.id,
    appointmentId: paymentData.reservedAppointment.id,
    email: calendar.selectedCustomer.email,
    firstName: calendar.selectedCustomer.firstName,
    saveCardForFutureBookings: paymentData.saveCard,
    treatments: paymentData.reservedAppointment.treatments.map((t) => ({
      serviceId: t.serviceId,
      name: t.name,
      price: t.price,
      category: t.category,
    })),
    addOns: paymentData.reservedAppointment.addOns.map((a) => ({
      id: a.id,
      name: a.name,
      price: a.price,
      recipient: a.recipient,
    })),
    timeSlot: {
      starts: new Date(
        paymentData.reservedAppointment.timeSlot.starts
      ).toISOString(),
      durationInMinutes:
        paymentData.reservedAppointment.timeSlot.durationInMinutes,
    },
    credits,
  };

  const api = new AxiosClient({});
  const createBookingResponse = await api.postRequest('/booking-v2', request);
  const bookingId = createBookingResponse.headers.location.split('/')[1];

  let attempts = 0;
  let bookingStatusData: IBookingResponse = {
    status: BookingStatus.Initial,
    failureCode: '',
    id: '',
    changeHistory: [],
    sagaState: '',
    timeZone: TimeZone['America/New_York'],
    failures: [],
  };
  while (attempts < maxAttempts) {
    const bookingStatusResponse = await api.getRequest(`/booking/${bookingId}`);
    bookingStatusData = bookingStatusResponse.data;
    if (
      bookingStatusData.status === BookingStatus.Success ||
      bookingStatusData.status === BookingStatus.Fail
    ) {
      break;
    }
    attempts += 1;
    await new Promise((f) => setTimeout(f, timeoutMs));
  }
  if (attempts === maxAttempts) {
    return {
      ...bookingStatusData,
      status: BookingStatus.Fail,
      failureCode: 'timeout',
      failureReason:
        'The system timed out waiting for the booking to complete. Please refresh and check if the appointment was booked before retrying.',
    };
  }
  return bookingStatusData;
});

function findLastCustom<T>(
  array: T[] | undefined,
  predicate: (value: T, index: number, array: T[]) => boolean
): T | undefined {
  if (array) {
    for (let i = array.length - 1; i >= 0; i -= 1) {
      if (predicate(array[i], i, array)) {
        return array[i];
      }
    }
  }
  return undefined;
}

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState,
  reducers: {
    selectEvent: (
      state: CalendarState,
      action: { payload: ScheduleEvent; type: string }
    ) => {
      state.selectedEvent = action.payload;
    },
    setAvailableResourcesForEdit: (
      state: CalendarState,
      action: { payload: IAvailabilityResources; type: string }
    ) => {
      state.availableResourcesForEdit = action.payload;
    },
    setAvailableResourcesForExtension: (
      state: CalendarState,
      action: { payload: IAvailabilityResources; type: string }
    ) => {
      state.availableResourcesForExtension = action.payload;
    },
    setAppointmentRescheduleDetails: (
      state: CalendarState,
      action: { payload: IRescheduleAppointmentRequest | null; type: string }
    ) => {
      state.appointmentRescheduleDetails = action.payload;
    },
    selectAppointmentForReschedule: (
      state: CalendarState,
      action: { payload: IAppointmentResponse | null; type: string }
    ) => {
      state.selectedAppointmentForReschedule = action.payload;
    },
    selectAppointmentForExtension: (
      state: CalendarState,
      action: { payload: IAppointmentResponse | null; type: string }
    ) => {
      state.selectedAppointmentForExtension = action.payload;
    },
    selectLocation: (
      state: CalendarState,
      action: { payload: ILocation | undefined; type: string }
    ) => {
      if (!action.payload) {
        state.selectedLocation = BLANK_LOCATION;
      } else {
        state.selectedLocation = action.payload;
      }
    },
    selectAvailability: (
      state: CalendarState,
      action: { payload: IAvailableSlot; type: string }
    ) => {
      state.selectedAvailability = action.payload;
    },
    selectServiceCategory: (
      state: CalendarState,
      action: { payload: string | null; type: string }
    ) => {
      state.selectedServiceCategory = action.payload;
    },
    selectCustomer: (
      state: CalendarState,
      action: { payload: ICustomer; type: string }
    ) => {
      state.selectedCustomer = action.payload;
    },
    selectCalendarDates: (
      state: CalendarState,
      action: {
        payload: {
          current: Date;
          start: Date;
          end: Date;
        };
        type: string;
      }
    ) => {
      state.selectedDateRange = action.payload;
    },
    setValidTherapistIds: (
      state: CalendarState,
      action: {
        payload: string[][];
        type: string;
      }
    ) => {
      state.validTherapistIds = action.payload;
    },
    setValidTreatmentIds: (
      state: CalendarState,
      action: {
        payload: string[][];
        type: string;
      }
    ) => {
      state.validTreatmentIds = action.payload;
    },
    setValidAddOnIds: (
      state: CalendarState,
      action: {
        payload: string[][];
        type: string;
      }
    ) => {
      state.validAddOnIds = action.payload;
    },
    setPendingAddAppointmentRequest: (
      state: CalendarState,
      action: { payload: IAppointmentRequest | null; type: string }
    ) => {
      state.pendingAddAppointmentRequest = action.payload;
    },
    setAppointmentReservationId: (
      state: CalendarState,
      action: { payload: string; type: string }
    ) => {
      state.appointmentReservationId = action.payload;
    },
    setShowCanceledAppointments: (
      state: CalendarState,
      action: { payload: boolean; type: string }
    ) => {
      state.showCanceledAppointments = action.payload;
    },
    resetBookingStatus: (state: CalendarState) => {
      state.bookingStatus = BookingStatus.Initial;
      state.bookingFailureCode = '';
      state.bookingFailureReason = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitBooking.pending, (state) => {
        state.bookingStatus = BookingStatus.Pending;
      })
      .addCase(submitBooking.fulfilled, (state, action) => {
        state.bookingStatus = action.payload.status;
        state.bookingFailureCode = action.payload.failureCode;
        state.bookingFailureReason =
          findLastCustom(
            action.payload.failures,
            (f) => f.failureCode === action.payload.failureCode
          )?.reason ?? '';
        if (action.payload.failureCode === 'timeout') {
          state.bookingFailureReason =
            'The system timed out waiting for the booking to complete. Please refresh and check if the appointment was booked before retrying.';
        }
      })
      .addCase(submitBooking.rejected, (state) => {
        state.bookingStatus = BookingStatus.Fail;
      })
      .addCase(pollForBookingResult.pending, (state) => {
        state.bookingStatus = BookingStatus.Pending;
      })
      .addCase(pollForBookingResult.fulfilled, (state, action) => {
        state.bookingStatus = action.payload.status;
        state.bookingFailureCode = action.payload.failureCode;
        state.bookingFailureReason =
          findLastCustom(
            action.payload.failures,
            (f) => f.failureCode === action.payload.failureCode
          )?.reason ?? '';
      })
      .addCase(pollForBookingResult.rejected, (state) => {
        state.bookingStatus = BookingStatus.Fail;
      });
  },
});

export const {
  selectEvent,
  setAvailableResourcesForEdit,
  setAvailableResourcesForExtension,
  setAppointmentRescheduleDetails,
  selectAppointmentForReschedule,
  selectAppointmentForExtension,
  selectLocation,
  selectAvailability,
  selectServiceCategory,
  selectCustomer,
  selectCalendarDates,
  setValidTherapistIds,
  setValidTreatmentIds,
  setValidAddOnIds,
  setPendingAddAppointmentRequest,
  setAppointmentReservationId,
  resetBookingStatus,
  setShowCanceledAppointments,
} = appCalendarSlice.actions;

export default appCalendarSlice.reducer;
