export interface IBusinessForm {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  customerReplyEmail: string;
  websiteUrl: string;
  logo: string;
  isActive: boolean;
}

export interface IBusiness {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  customerReplyEmail: string;
  websiteUrl: string;
  logo: string;
  isActive: boolean;
  cancellationPolicy: ICancellationPolicy;
}

export enum CancellationValueType {
  Percentage = 'percent',
  Dollar = 'dollar',
}

export interface ICancellationPolicy {
  verbiage: string;
  hours: number;
  unitType: CancellationValueType;
  value: number;
}

export interface ICancellationPolicyForm {
  id: string;
  verbiage: string;
  hours: number;
  unitType: CancellationValueType;
  value: number;
}

export const BLANK_CANCELLATION_POLICY: ICancellationPolicyForm = {
  id: '',
  verbiage: '',
  hours: 12,
  unitType: CancellationValueType.Percentage,
  value: 0.5,
};
export const BLANK_BUSINESS: IBusiness = {
  id: '',
  name: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  phone: '',
  customerReplyEmail: '',
  websiteUrl: '',
  logo: '',
  isActive: false,
  cancellationPolicy: BLANK_CANCELLATION_POLICY,
};
