
const configuration = {
    welcomeMessage: "Hello from DEV",
    urls: {
        baseUrl: "https://dev-api.spaccurate.com",
        IDENTITY_SERVER: "https://dev-iam.spaccurate.com",
        IDENTITY_SERVER_AUTHORIZE_ENDPOINT: "/connect/authorize",
        IDENTITY_SERVER_TOKEN_ENDPOINT: "/connect/token",
        IDENTITY_SERVER_LOGOUT_ENDPOINT: "/connect/endsession",
        availability: "/availability",
        SQUARE_APPLICATION_ID: "sandbox-sq0idb-6kVBkaeRS4_e1GfzcRIj8g",
        SQUARE_LOCATION_ID: "LPJVFA1HS2EBC",
        STRIPE_DASHBOARD: "https://dashboard.stripe.com/{{accountId}}/test/dashboard"
    },
    INSTRUMENTATION_KEY: "35435f43-237b-423a-b582-18b0994d6678",
    GOOGLE_OAUTH_CLIENT_ID: '243029629157-m766ei9q1ltbl9cv1ahvo0mqpr4dras7.apps.googleusercontent.com',
    STRIPE_PUBLISHABLE_KEY: "pk_test_51OWmWWAbGz2rAwn21rPtucVzpC1tmD9pOHbNqcstiy2J7y6yz2lopSU88q5wzzIilhrBOqcKoOKZXIH8MkKUCpeR0076rB6dlT"
}

export default configuration
