import { createSlice } from '@reduxjs/toolkit';
import { BLANK_LOCATION, ILocation } from '../business/locations/types';
import { ScheduleEvent } from '../calendar/types';

interface TherapistScheduleState {
  selectedEvent: ScheduleEvent;
  selectedLocation: ILocation;
  selectedDateRange?: {
    current: Date;
    start: Date;
    end: Date;
  };
}

const initialState: TherapistScheduleState = {
  selectedEvent: {},
  selectedLocation: BLANK_LOCATION,
  selectedDateRange: undefined,
};

export const TherapistScheduleSlice = createSlice({
  name: 'therapistSchedule',
  initialState,
  reducers: {
    selectEvent: (
      state: TherapistScheduleState,
      action: { payload: ScheduleEvent; type: string }
    ) => {
      state.selectedEvent = action.payload;
    },
    selectLocation: (
      state: TherapistScheduleState,
      action: { payload: ILocation | undefined; type: string }
    ) => {
      if (!action.payload) {
        state.selectedLocation = BLANK_LOCATION;
      } else {
        state.selectedLocation = action.payload;
      }
    },
    selectCalendarDates: (
      state: TherapistScheduleState,
      action: {
        payload: { current: Date; start: Date; end: Date };
        type: string;
      }
    ) => {
      state.selectedDateRange = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { selectEvent, selectLocation, selectCalendarDates } =
  TherapistScheduleSlice.actions;

export default TherapistScheduleSlice.reducer;
