import { TimeZone } from '@src/features/business/locations/types';
import { randomBytes, createHash } from 'crypto';
import moment from 'moment';
import { Theme } from 'react-select';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj: Object) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num: number) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html: string) =>
  html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
// const isToday = (date: Date) => {
//   const today = new Date();
//   return (
//     date.getDate() === today.getDate() &&
//     date.getMonth() === today.getMonth() &&
//     date.getFullYear() === today.getFullYear()
//   );
// };

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value: Date,
  formatting: Intl.DateTimeFormatOptions
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
// export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
//   const date = new Date(value);
//   let formatting = { month: 'short', day: 'numeric' };

//   if (toTimeForCurrentDay && isToday(date)) {
//     formatting = { hour: 'numeric', minute: 'numeric' };
//   }

//   return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
// };

// ** React Select Theme Colors
export const selectThemeColors = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

export const base64URLEncode = (buffer: Buffer) =>
  buffer
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

export const sha256 = (buffer: Buffer) =>
  createHash('sha256').update(buffer).digest();

export const generatePkceVerifier = () => base64URLEncode(randomBytes(64));

export const generatePkceChallenge = (verifier: string) =>
  base64URLEncode(sha256(Buffer.from(verifier)));

export const getDayIndex = (day: string) => {
  switch (day) {
    case 'monday': {
      return 0;
    }
    case 'tuesday': {
      return 1;
    }
    case 'wednesday': {
      return 2;
    }
    case 'thursday': {
      return 3;
    }
    case 'friday': {
      return 4;
    }
    case 'saturday': {
      return 5;
    }
    case 'sunday': {
      return 6;
    }
    default: {
      return '-1';
    }
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

const timeFormat = 'hh:mm A';

export const ConvertToTime = (value: any) => {
  const roundedValue = Math.round(value);

  const time = moment()
    .startOf('day')
    .add(roundedValue, 'minutes')
    .format(timeFormat);
  return time;
};

export const ConvertFromTime = (value: string) => {
  const time = moment(value, timeFormat);
  const minutes = time.hour() * 60 + time.minute();
  return minutes;
};

export const TimeZoneToIANAId = (timeZone: TimeZone | undefined) => {
  switch (timeZone) {
    case TimeZone['Alaskan Standard Time']:
      return 'America/Anchorage';
    case TimeZone['US Mountain Standard Time']:
      return 'America/Phoenix';
    case TimeZone['Central Standard Time']:
      return 'America/Chicago';
    case TimeZone['Eastern Standard Time']:
      return 'America/New_York';
    case TimeZone['Hawaiian Standard Time']:
      return 'Pacific/Honolulu';
    case TimeZone['Mountain Standard Time']:
      return 'America/Denver';
    case TimeZone['Pacific Standard Time']:
      return 'America/Los_Angeles';
    default:
      return timeZone?.toString() ?? '';
  }
};

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatMoneyWithoutCents = (value: number) => {
  const withCents = moneyFormatter.format(value);
  return withCents.replace(/\.\d+$/, '');
};

export const ConvertDateToSpecificTimeZoneString = (
  date: Date,
  timeZone: TimeZone | undefined,
  style?: 'dateTime' | 'date' | 'time' | undefined
) => {
  if (!timeZone) return '';

  if (style === 'dateTime') {
    return date
      .toLocaleDateString('en-Us', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: TimeZoneToIANAId(timeZone),
      })
      .replaceAll(',', '');
  }

  if (style === 'date') {
    return date
      .toLocaleDateString('en-Us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        timeZone: TimeZoneToIANAId(timeZone),
      })
      .replaceAll(',', '');
  }

  if (style === 'time') {
    return date.toLocaleTimeString('en-Us', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: TimeZoneToIANAId(timeZone),
    });
  }

  return date.toLocaleDateString('en-US', {
    timeZone: TimeZoneToIANAId(timeZone),
  });
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && !Number.isNaN(date.getTime());
};

/* essentially removes the timezone offset of a date. */
/* (ie. 12am eastern would be 5am UTC. This function would return 12am UTC ) */
export const getISODateInUtcIgnoringTimezone = (date: Date) => {
  if (!isValidDate(date)) return '';

  const offset = date.getTimezoneOffset();
  const offsetDate = new Date(date.getTime() - offset * 60000);
  return offsetDate.toISOString();
};

export const getLocalDateOnlyFromISO = (
  dateString: string,
  timeZone: TimeZone
) => {
  const localDateOnly = getISODateInUtcIgnoringTimezone(
    new Date(
      ConvertDateToSpecificTimeZoneString(new Date(dateString), timeZone)
    )
  ).split('T')[0];
  return localDateOnly;
};

/* offsets date based on difference between local machine timezone and provided timezone */
/* [local machine offset] - [input timezone offset] = [offset to apply to date] */
/* [PST (offset -8)]  -  [EST (offset -5)] = -3 => returns provided date -3 hours */
export const ConvertDateToSpecificTimeZoneDate = (
  date: Date,
  timeZone: TimeZone | undefined
) => {
  if (!timeZone) return date;

  const oldTimeOffset = date.getTimezoneOffset();

  const convertedDateString = date
    .toLocaleDateString('en-Us', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: TimeZoneToIANAId(timeZone),
    })
    .replaceAll(',', '');

  const dateIgnoringTimezone = getISODateInUtcIgnoringTimezone(
    new Date(convertedDateString)
  );

  const timezoneOffsetInMs =
    date.getTime() - new Date(dateIgnoringTimezone).getTime();
  const offset = timezoneOffsetInMs / 60000 - oldTimeOffset;

  const convertedDate = new Date(date.getTime() + offset * 60000);

  return convertedDate;
};

export const getEnumKeyByValue = <T extends object>(
  enumObject: T,
  value: string
): keyof T | string => {
  const keys = Object.keys(enumObject);

  let result: keyof T | string | undefined;
  keys.some((key) => {
    const enumValue = enumObject[key as keyof T] as unknown as string;
    if (enumValue === value) {
      result = key as keyof T;
      return true;
    }
    return false;
  });

  return result ?? '';
};

export const parseTimeSpanToTimeInPast = (
  timeSpan: string,
  timeToGoBackFrom: Date
): Date => {
  let [daysStr, timeStr] = timeSpan.split('.');

  if (!timeStr) {
    timeStr = daysStr;
    daysStr = '0';
  }

  const [hoursStr, minutesStr, secondsStr] = timeStr.split(':');

  const days = parseInt(daysStr, 10);
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  const pastTime = new Date(timeToGoBackFrom);

  // Subtract the specified days, hours, minutes, and seconds
  pastTime.setDate(pastTime.getDate() - days);
  pastTime.setHours(pastTime.getHours() - hours);
  pastTime.setMinutes(pastTime.getMinutes() - minutes);
  pastTime.setSeconds(pastTime.getSeconds() - seconds);

  return pastTime;
};

export const formatTimeSpan = (timeSpan: string): string => {
  // Split the input string into days, hours, minutes, and seconds
  let [daysStr, timeStr] = timeSpan.split('.');

  if (!timeStr) {
    timeStr = daysStr;
    daysStr = '0';
  }

  const [hoursStr, minutesStr, secondsStr] = timeStr.split(':');

  // Parse days, hours, minutes, and seconds as integers
  const days = parseInt(daysStr, 10);
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  // Create a human-readable string
  let result = '';
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}`;
  }
  if (hours > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (minutes > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
  if (seconds > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${seconds} second${seconds > 1 ? 's' : ''}`;
  }
  if (result.length === 0) {
    result = '0 seconds';
  }

  return result;
};

export const sortDate = (
  dateA: Date | undefined | null,
  dateB: Date | undefined | null
) => {
  if (!dateA || !dateB) return 0;
  if (dateA > dateB) {
    return 1;
  }

  if (dateB > dateA) {
    return -1;
  }

  return 0;
};

export const sortNumber = (
  numA: number | undefined | null,
  numB: number | undefined | null
) => {
  if (!numA || !numB) return 0;
  if (numA > numB) {
    return 1;
  }

  if (numB > numA) {
    return -1;
  }

  return 0;
};

export const tryHandleQueryParam = (
  paramKey: string,
  hasParamCallback: (paramValue: string) => void,
  doesNotHaveParamCallback: (() => void) | null
) => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const paramValue = params.get(paramKey);
  if (paramValue) {
    hasParamCallback(paramValue);
  } else if (doesNotHaveParamCallback) doesNotHaveParamCallback();
};

export const updateOrAddQueryParam = (key: string, value: string) => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  if (params.has(key)) {
    params.set(key, value);
  } else {
    params.append(key, value);
  }

  url.search = params.toString();
  window.history.replaceState({}, '', url.href);
};

export const truncateString = (input: string, maxLength: number): string => {
  if (input.length > maxLength) {
    return `${input.slice(0, maxLength)}...`;
  }
  return input;
};
