/* eslint-disable no-empty-pattern */
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosClient } from '@src/app/AxiosClient';
import appConfig from '@src/configs/appConfig';
import { AxiosRequestConfig, AxiosError } from 'axios';

interface Meta {
  locationHeader: string | undefined;
}

export type HttpError = { data: { code: string; reason: string } };

export const axiosBaseQuery =
  (
    { baseUrl, useAuthToken }: { baseUrl: string; useAuthToken: boolean } = {
      baseUrl: '',
      useAuthToken: false,
    }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown,
    unknown,
    Meta
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      let contentType = 'application/json';
      if (headers && headers['Content-Type']) {
        contentType = headers['Content-Type'];
      }
      const axios = new AxiosClient({ baseUrl, useAuthToken, contentType });
      const result = await axios.client({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return {
        data: result.data,
        meta: { locationHeader: result.headers.location },
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// Define a service using a base URL and expected endpoints
export const api = createApi({
  // reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: appConfig.urls.baseUrl,
    useAuthToken: true,
  }),
  tagTypes: [
    'availability',
    'timeslot-pricing',
    'hours',
    'rooms',
    'account',
    'business-info',
    'customer',
    'customers',
    'search-customers',
    'staff',
    'staff-member',
    'locations',
    'location',
    'services',
    'staff-treatments',
    'operations-exceptions',
    'staff-member-availability',
    'therapist-location-availability',
    'available-therapists',
    'schedule',
    'therapist-schedule',
    'customer-appointments',
    'customer-appointment-history',
    'order',
    'booking-order',
    'location-orders',
    'customer-orders',
    'customer-cards',
    'checkout-devices',
    'rates',
    'report-data',
    'payroll-summary-report',
    'therapist-payroll-report',
    'oauth-integration',
    'calendar-integration',
    'google-calendar-options',
    'cancellation-policy',
    'business-gift-cards',
    'gift-card',
    'search-gift-cards',
    'staff-add-ons',
    'treatment-add-ons',
    'stripe-connected-account',
    'stripe-connected-account-link',
  ],
  endpoints: () => ({}),
});

export const apiNoAuth = createApi({
  // reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: appConfig.urls.baseUrl,
    useAuthToken: false,
  }),
  tagTypes: [
    'account',
    'business-info',
    'staff',
    'staff-member',
    'locations',
    'location',
    'services',
    'staff-treatments',
  ],
  endpoints: () => ({}),
});

export const apiExample = createApi({
  reducerPath: 'apiExample',
  baseQuery: axiosBaseQuery({
    baseUrl: 'https://datausa.io',
    useAuthToken: false,
  }),
  tagTypes: ['example-report-data'],
  endpoints: () => ({}),
});

export const {} = api;
export const {} = apiNoAuth;
export const {} = apiExample;
